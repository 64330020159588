import format from 'date-fns/format'
import api from '@/api'
import * as turf from '@turf/turf'
import {
  alphabeticalIndex,
  roundTo,
  mod
} from '@/helper/utils'
import {
  uniqueValues
} from '@/helper/array'

const getLenthLineStrings = (lineFeatures) => {
  let lines = []
  let length = 0
  let point1, point2

  lineFeatures.forEach((currentFeature) => {
    // Length array
    const segments = turf.lineSegment(currentFeature)

    for(let features = segments.features, count = features.length, i = 0, started = false, backsteps = 0; i < count - backsteps + 1;) {
      try {
        let direction = started ? 1 : -1
        i += direction

        const point11 = turf.point(features[mod(i, count)].geometry.coordinates[0])
        const point12 = turf.point(features[mod(i, count)].geometry.coordinates[1])
        const bearing1 = turf.bearing(point11, point12)
  
        const point21 = turf.point(features[mod(i + 1, count)].geometry.coordinates[0])
        const point22 = turf.point(features[mod(i + 1, count)].geometry.coordinates[1])
        const bearing2 = turf.bearing(point21, point22)

        if (!started) {
          backsteps = backsteps + 1
    
          if (Math.abs(bearing1 - bearing2) > 3) {
            direction = 1
            started = true
            point1 = turf.point(features[mod(i + 1, count)].geometry.coordinates[0])
          }

          continue
        }

        length += turf.length(features[mod(i, count)])

        if (Math.abs(bearing1 - bearing2) > 3) {
          point2 = point21
    
          lines = [
            ...lines,
            turf.lineString(
              [point1.geometry.coordinates, point2.geometry.coordinates],
              { length: (length * 1000).toFixed(1) }
            )
          ]
    
          length = 0
          point1 = point12
        }
      } finally {
        // do nothing
      }
    }
  })

  return lines
}

export default {
  namespaced: true,

  state: {
    loading: false,

    // List/Map View
    items: [],
    loadingItems: false,
    total: 0,

    myComps: [],

    loadingCompRevisions: false,
    compRevisions: [],
    selectedComp: null,
    hoveredCompCoord: null,
    mapNavMode: 'search',

    userShortlistModal: false,
    compIdForShortlist: null,
    addingToUserShortlist: false,
    loadingShortlistComps: false,
    loadingUserShortlist: false,
    shortlistComps: [],
    sledUserShortlistId: null,

    reportingTemplateSelectionModal: false,

    compEditModal: false,
    compIdForEdit: null,

    columnUpdateDlg: false,
    columnUpdateMode: 'list', // list | shortlist

    loadingCompDetail: false,
    compDetail: null,
    loadingPointComps: false,
    pointComps: null,

    compTransactions: [],
    loadingCompTransactions: false,

    state_abbreviation: null,
    gnafProperties: [],
    // propertyPolygons: [],
    selectedGnafProperty: null,
    loadingGnafProperties: false,
    gnafPropertiesSource: null
  },
  mutations: {
    SET_LOADING(state, v) { state.loadingItems = v },

    // List/Map items
    SET_ITEMS_LOADING(state, v) { state.loadingItems = v },
    SET_ITEMS(state, v) { state.items = v },
    SET_TOTAL(state, v) { state.total = v },

    SET_MY_COMPS(state, v) { state.myComps = v },

    SET_SELECTED_COMP(state, v) { state.selectedComp = v },
    // SET_LOADING_COMP_REVISIONS(state, v) { state.loadingCompRevisions = v },
    // SET_COMP_REVISIONS(state, v) { state.compRevisions = v },
    SET_COMP_COORD(state, v) { state.hoveredCompCoord = v },
    SET_MAP_NAV_MODE(state, v) { state.mapNavMode = v },

    SET_TYPES(state, v) { state.propertyTypes = v },
    SET_PROPERTIES(state, v) { state.properties = v },

    // Reporting
    SET_REPORTING_TEMPLATE_MODAL(state, v) { state.reportingTemplateSelectionModal = v },

    // Shortlist
    SET_USER_SHORTLIST_MODAL(state, v) { state.userShortlistModal = v },
    SET_COMPID_FOR_SHORTLIST(state, v) { state.compIdForShortlist = v },
    SET_ADDING_TO_SHORTLIST(state, v) { state.addingToUserShortlist = v },
    SET_LOADING_USER_SHORTLIST(state, v) { state.loadingUserShortlist = v },
    SET_LOADING_SHORTLIST_COMPS(state, v) { state.loadingShortlistComps = v },
    SET_SHORTLIST_COMPS(state, v) { state.shortlistComps = v },
    SET_SELECTED_USERSHORTLIST_ID(state, v) { state.sledUserShortlistId = v },

    SET_COLUMN_EDIT_MODAL(state, v) { state.columnUpdateDlg = v },
    SET_COLUMN_EDIT_MODE(state, v) { state.columnUpdateMode = v },

    // Edit
    SET_EDIT_MODAL(state, v) { state.compEditModal = v },
    SET_COMPID_FOR_EDIT(state, v) { state.compIdForEdit = v },

    SET_LOADING_COMP_DETAIL(state, v) { state.loadingCompDetail = v },
    SET_COMP_DETAIL(state, v) { state.compDetail = v },

    SET_LOADING_COMPS_BY_POINT(state, v) { state.loadingPointComps = v },
    SET_COMPS_SAME_POINT(state, v) { state.pointComps = v },

    // SET_LOADING_COMP_TRANSACTIONS(state, v) { state.loadingCompTransactions = v },
    // SET_COMP_TRANSACTIONS(state, v) { state.compTransactions = v },

    SET_STATE_ABBREVIATION(state, v) { state.state_abbreviation = v },
    SET_LOADING_GNAF_PROPERTIES(state, v) { state.loadingGnafProperties = v },
    SET_PROPERTY_POLYGONS(state, properties) {
      const gnafProperties = properties.map((v) => {
        let gnaf_property_pid = null

        if (v.address) {
          gnaf_property_pid = +v.address.gnaf_property_pid
        } else if (v.propid) {
          gnaf_property_pid = +v.propid
        } else if (v.pfi) {
          gnaf_property_pid = +v.pfi
        }

        return {
          geom: v.geom,
          address: v.address,
          gnaf_property_pid
        }
      })

      state.gnafProperties = gnafProperties.sort((a, b) => a.gnaf_property_pid < b.gnaf_property_pid ? -1 : (a.gnaf_property_pid > b.gnaf_property_pid ? 1 : 0))
    },
    SET_GNAF_PROPERTIES(state, { state_abbreviation, properties }) {
      if (properties) {
        if (state_abbreviation === 'VIC') {
          state.gnafProperties = properties
  
          let geom = null
  
          for(const p of properties) {
            const next = turf.multiPolygon(
              p.geom.coordinates,
              {
                pfi: p.pfi,
                base_pfi: p.base_pfi
              }
            )

            geom = geom ? turf.union(geom, next) : next
          }

          // state.mergedPolygonGeom = geom
        } else if (state_abbreviation === 'NSW') {
          state.gnafProperties = properties

          let geom = null

          for(const p of state.gnafProperties) {
            geom = geom ? turf.union(geom, p.geom) : p.geom
          }

          // state.mergedPolygonGeom = geom
        }
      }
    },
    SET_SELECTED_GNAF_PROPERTY(state, v) { state.selectedGnafProperty = v },
    SET_GNAF_PROPERTIES_SOURCE(state, v) { state.gnafPropertiesSource = v }
  },
  actions: {
    /**
     * Get comp details
     */
    async getCompDetails({ commit }, {
      compId,
      salesType,
      isSetState = true
    }) {
      commit('SET_LOADING_COMP_DETAIL', true)

      try {
        const res = await api.get(`/comps/by_comp_id/${compId}`, {
          params: {
            salesType: salesType
          }
        })

        if (isSetState) {
          commit('SET_COMP_DETAIL', res.data.item)
        } else {
          return res.data.item
        }
      } finally {
        commit('SET_LOADING_COMP_DETAIL', false)
      }
    },

    /**
     * Get comp details
     */
    async getCompDetail({ commit }, {
      compId,
      salesType,
      with_full_detail
    }) {
      try {
        return api.get(`/comps/by_comp_id/${compId}`, {
          params: {
            salesType: salesType,
            with_full_detail
          }
        })
      } finally {
        commit('SET_LOADING_COMP_DETAIL', false)
      }
    },

    async getCompsByPoint({ commit }, {
      salesType,
      lat, lng,
      filterQuery
    }) {
      commit('SET_LOADING_COMPS_BY_POINT', true)

      try {
        const res = await api.get('/comps/by_point', {
          params: {
            salesType,
            lat, lng,
            filterQuery
          }
        })

        commit('SET_COMPS_SAME_POINT', res.data.items)
      } finally {
        commit('SET_LOADING_COMPS_BY_POINT', false)
      }
    },

    /**
     * Get gnaf properties
     */
    async getPropertyPolygons({ commit }, {
      state_abbreviation,
      address_detail_pids,
      gnaf_property_pids,
      view_pfis,
      cadids
    }) {
      const res = await api.get('/gnaf/properties', {
        params: {
          state: state_abbreviation,
          address_detail_pids,
          gnaf_property_pids,
          view_pfis,
          cadids
        }
      })

      commit('SET_PROPERTY_POLYGONS', res.data.properties)
    },

    /**
     * Get gnaf properties
     */
    async getGnafProperties({ state, commit }, {
      state_abbreviation,
      address_detail_pids,
      gnaf_property_pids,
      view_pfis,
      cadids
    }) {
      commit('SET_LOADING_GNAF_PROPERTIES', true)
      commit('SET_GNAF_PROPERTIES', {
        state_abbreviation: state_abbreviation || state.state_abbreviation,
        properties: []
      })

      try {
        const res = await api.get('/gnaf/properties', {
          params: {
            state: state_abbreviation || state.state_abbreviation,
            address_detail_pids,
            gnaf_property_pids,
            view_pfis,
            cadids
          }
        })

        commit('SET_GNAF_PROPERTIES', {
          state_abbreviation: state_abbreviation || state.state_abbreviation,
          properties: res.data.properties
        })
        commit('SET_SELECTED_GNAF_PROPERTY', null)
      } finally {
        commit('SET_LOADING_GNAF_PROPERTIES', false)
      }
    },

    /**
     * 
     * @param {*} _ 
     * @param {*} payload { salesType: 'comps', soldDt: '10/10/2022', page: 1, itemsPerPage: 10 }
     * @returns 
     */
    async getComps({ commit }, payload) {
      commit('SET_ITEMS_LOADING', true)
      commit('SET_SELECTED_COMP', null)

      try {
        const res = await api.get('/comps/search', {
          params: payload,
          signal: payload.signal
        })

        const { items, total } = res.data

        commit('SET_ITEMS', items.map((item) => ({
          ...item,
          expand: false,
          exporting: false,
          unlocking: false
        })))
        commit('SET_TOTAL', total)
      } finally {
        commit('SET_ITEMS_LOADING', false)
      }
    },
    getCompsFromAddressDetailPid(_, { address_detail_pid, salesType }) {
      return api.get(`/comps/by_address_detail_pid/${address_detail_pid}?salesType=${salesType}`)
    },
    getCompTransactions: (_, {
      address_detail_pids
    }) => {
      return api.get('/comps/history', {
        params: {
          address_detail_pids
        }
      })
    },
    getSourcesOfInfo: (_, source_of_info_ids) => {
      return api.get('/source_of_infos/by_ids', {
        params: {
          ids: source_of_info_ids
        }
      })
    },
    getCompUpdates: async (_, { compId, salesType }) => {
      return api.get(`comps/updates/${compId}`, {
        params: {
          salesType
        }
      })
    },
    selectComp({ commit }, comp) {
      commit('SET_SELECTED_COMP', comp)
    },
    hoverComp({ commit }, coord) {
      commit('SET_COMP_COORD', coord)
    },
    setMapNavMode({ commit }, mode) {
      commit('SET_MAP_NAV_MODE', mode)
    },

    /**
     * 
     * @param {*} _ 
     * @param {*} payload { salesType: 'comps', soldDt: '10/10/2022', page: 1, itemsPerPage: 10 }
     * @returns 
     */
    getCompsGroupedByAddress(_, payload) {
      return api.get('/comps/public/group_by_address', {
        params: payload
      })
    },

    addUserShortlist: async ({ commit }, { title, salesType }) => {
      try {
        commit('SET_ADDING_TO_SHORTLIST', true)

        await api.post('shortlist/user_shortlist', {
          title,
          salesType
        })
      } finally {
        commit('SET_ADDING_TO_SHORTLIST', false)
      }
    },

    updateUserShortlist: async (_, { id, title }) => {
      return api.put(`shortlist/user_shortlist/${id}`, {
        title
      })
    },

    deleteUserShortlist: async (_, id) => {
      return api.delete(`shortlist/user_shortlist/${id}`)
    },

    toggleToUserShortlist: async (_, { compId, userShortlistId, isAdd }) => {
      return api.post('shortlist/toggle_to_user_shortlist', {
        compId,
        userShortlistId,
        isAdd
      })
    },

    /* payload
      {
        compId,
        userShortlistId
      }
    */
    deleteShortlist: (_, payload) => {
      return api.post('/shortlist/delete_shortlist', payload)
    },

    getUserShortlist: async (_, salesType) => {
      return api.get('shortlist/user_shortlist', {
        params: {
          salesType
        }
      })
    },

    getShortlistedComps: async ({ commit }, { salesType, userShortlistId }) => {
      try {
        commit('SET_LOADING_SHORTLIST_COMPS', true)
        
        const response = await api.get('shortlist', {
          params: {
            salesType,
            userShortlistId
          }
        })

        const items = response.data.items.map((item, i) => {
          return {
            ...item,
            label: alphabeticalIndex(i, true)
          }
        })
        
        commit('SET_SHORTLIST_COMPS', items)

        return response
      } finally {
        commit('SET_LOADING_SHORTLIST_COMPS', false)
      }
    },

    setPropertiesGeomSourceState: ({ commit }, {
      areasLabels = false,
      isMulti = false,
      lengthLabels = true,
      addressPolygons
    }) => {
      let mergedAreaFeature = null
      let areasLabelFeatures = []
      let boundaryFeatures = []
      let multiLabelFeatures = []

      // area fill
      addressPolygons.forEach((addressPolygon) => {
        mergedAreaFeature = mergedAreaFeature ? turf.union(mergedAreaFeature, addressPolygon.geom) : turf.feature(addressPolygon.geom)
      })

      // boundary
      addressPolygons.forEach((addressPolygon) => {
        const linesFeatureCollection = turf.polygonToLine(addressPolygon.geom)
        
        linesFeatureCollection.features.forEach((lineFeature) => {
          const foundEqualPolygon = boundaryFeatures.find((v) => turf.booleanEqual(v, lineFeature))

          if (!foundEqualPolygon) {
            boundaryFeatures = [...boundaryFeatures, lineFeature]
          }
        })

      })

      // area label
      if (areasLabels) {
        let distinct = []

        addressPolygons.forEach((addressPolygon) => {
          turf.flattenEach(turf.multiPolygon(addressPolygon.geom.coordinates), (feature) => {
            const foundEqualPolygon = distinct.find((v) => turf.booleanEqual(v, feature))

            if (!foundEqualPolygon) {
              distinct.push(feature)
            }
          })
        })

        areasLabelFeatures = distinct.map((p) => {
          return turf.center(p.geometry, {
            properties: {
              area: turf.area(p.geometry).toLocaleString('en',{ minimumFractionDigits:2, maximumFractionDigits:2 })
            }
          })
        })
      }

      // multi labels
      if (isMulti) {
        let distinct = []

        addressPolygons.forEach((addressPolygon) => {
          turf.flattenEach(turf.multiPolygon(addressPolygon.geom.coordinates), (feature) => {
            const foundEqualPolygon = distinct.find((v) => turf.booleanEqual(v, feature))

            if (!foundEqualPolygon) {
              distinct.push({
                ...feature,
                properties: {
                  label: addressPolygon.label
                }
              })
            }
          })
        })

        multiLabelFeatures = distinct.map((p) => {
          return turf.center(p.geometry, {
            properties: {
              multi_sale_index: p.properties.label
            }
          })
        })
      }

      const featureCollections = [].concat(
        mergedAreaFeature,
        boundaryFeatures,
        areasLabelFeatures,
        multiLabelFeatures,
        lengthLabels ? getLenthLineStrings(boundaryFeatures) : []
      )

      commit('SET_GNAF_PROPERTIES_SOURCE', turf.featureCollection(featureCollections))
    },
    /**
     * Creates a feature collections composed of polygons, centers, multi sale indexes and lengths from gnaf properties
     * and set gnafPropertiesSource state value
     */
    setGnafPropertiesSourceState: ({ commit, state }, {
      polygons = true,
      // areasLabels = false,
      // isMulti = false,
      // lengthLabels = false,
      // gnafPropertyPids = [],
      detailMenuMode
    }) => {
      // const geom = state.selectedGnafProperty ? state.selectedGnafProperty.geom : state.mergedPolygonGeom
      let profileProperties = [],
          profileAreasLabels = [],
          polygonFeatures = []
          // unionPolygon = null

      if (state.gnafProperties.length) {
        // const unionPolygon = geom.type === 'Feature' ? geom.geometry : geom
        // const unionFeature = geom.type === 'Feature' ? geom : turf.feature(geom)

        // if (multiSaleLabels) {
        //   gnafPropertyPids.forEach((gnaf_property_pid, i) => {
        //     const profileProperty = state.gnafProperties.find((p) => +p.pfi === +gnaf_property_pid)

        //     if (profileProperty) {
        //       profileProperties.push(turf.center(profileProperty.geom, {
        //         properties: {
        //           multi_sale_index: alphabeticalIndex(i, true)
        //         }
        //       }))
        //     }
        //   })
        // }

        // if (areasLabels) {
          // profileAreasLabels = state.gnafProperties
          //   .filter((p) => p.base_pfi)
          //   .map((p) => {
          //     return turf.center(p.geom, {
          //       properties: {
          //         area: turf.area(p.geom).toFixed(1).toLocaleString()
          //       }
          //     })
          //   })
          // if (state.compDetail) {
          //   let addresses = []

          //   if (state.compDetail.is_multi_sale) {
          //     addresses = state.compDetail.individuals.map((v) => v.address)
          //   } else {
          //     addresses = [state.compDetail.address]
          //   }

          //   addresses.forEach((address) => {
          //     const profileProperty = state.gnafProperties.find((p) => p.pfi === address.gnaf_property_pid)

          //     if (profileProperty) {
          //       profileAreasLabels.push(turf.center(profileProperty.geom, {
          //         properties: {
          //           area: turf.area(profileProperty.geom).toFixed(1),
          //         }
          //       }))
          //     }
          //   })
          // } else {
          //   profileAreasLabels.push(turf.center(geom, {
          //     properties: {
          //       area: turf.area(geom).toFixed(1),
          //     }
          //   }))
          // }
        // }
        
        if (detailMenuMode === 0) {
          state.gnafProperties.forEach((p) => {
            const polygonFeature = turf.multiPolygon(p.geom.coordinates, {
              dashed: '0'
            })
            const foundEqualPolygon = polygonFeatures.find((v) => turf.booleanEqual(v, polygonFeature))
  
            if (!foundEqualPolygon) {
              polygonFeatures.push(polygonFeature)
            }
          })
        } else {
          state.gnafProperties.forEach((p) => {
            const polygonFeature = turf.multiPolygon(p.geom.coordinates, {
              dashed: p.base_pfi ? '0' : '1'
            })
            const foundEqualPolygon = polygonFeatures.find((v) => turf.booleanEqual(v, polygonFeature))
            
            if (!foundEqualPolygon) {
              polygonFeatures.push(polygonFeature)
            }
          })
        }

        // if (areasLabels) {
        //   profileAreasLabels = polygonFeatures
        //     .filter((p) => p.properties.dashed === '0')
        //     .map((p) => {
        //       return turf.center(p.geometry, {
        //         properties: {
        //           area: turf.area(p.geometry).toLocaleString('en',{ minimumFractionDigits:2, maximumFractionDigits:2 })
        //         }
        //       })
        //     })
        // }

        // if (lengthLabels) {
        //   polygonFeatures.filter((p) => p.properties.base_pfi !== '').forEach((p) => {
        //     unionPolygon = unionPolygon === null ? p.geometry : turf.union(unionPolygon, p.geometry)
        //   })
        // }

        const featureCollections = [].concat(
          polygons ? polygonFeatures : [],
          profileAreasLabels,
          profileProperties,
          // lengthLabels ? getLenthLineStrings(unionPolygon) : []
        )

        commit('SET_GNAF_PROPERTIES_SOURCE', turf.featureCollection(featureCollections))
      } else {
        commit('SET_GNAF_PROPERTIES_SOURCE', null)
      }
    }
  },

  getters: {
    pageCount: (state) => {
      return Math.ceil(state.total / 10)
    },
    isShortlisted: (state) => (id) => {
      return state.shortlist.findIndex((v) => v.comp_id == id) !== -1
    },
    termPrice: () => (item, i, is_gst_excl) => {
      if (item.sale_price && item.settlement_term_details[i - 1] && item.settlement_term_details[i - 1][0]) {
        const price = item.sale_price * item.settlement_term_details[i - 1][0] * 0.01

        if (item.gst_included) {
          return is_gst_excl ? Math.floor(price * 10 / 11) : price
        } else {
          return is_gst_excl ? price : null
        }
      } else {
        return null
      }
    },
    termDate: () => (item, i) => {
      if (item.sale_dt && item.settlement_term_details[i - 1] && item.settlement_term_details[i - 1][1]) {
        const [dd, mm, yyyy] = item.sale_dt.split('/')

        return format(new Date(yyyy, +mm - 1 + +item.settlement_term_details[i - 1][1], dd), 'dd/MM/yyyy')
      } else {
        return null
      }
    },
    propertyLotSectionPlans: (state) => {
      let lotSectionPlans = []

      if (state.state_abbreviation === 'VIC') {
        state.gnafProperties.forEach((property) => {
          if (property.parcels) {
            property.parcels.forEach((vv) => {
              lotSectionPlans = [
                ...lotSectionPlans,
                {
                  lot: vv.lot_number,
                  section: null,
                  plan: vv.plan_no
                }
              ]
            })
          }
        })
      } else if (state.state_abbreviation === 'NSW') {
        // TODO
      }

      return uniqueValues(lotSectionPlans)
    },
    gnafLandArea: (state) => {
      if (state.selectedGnafProperty) {
        return roundTo(turf.area(state.selectedGnafProperty.geom))
      } else if (state.mergedPolygonGeom) {
        return roundTo(turf.area(state.mergedPolygonGeom))
      } else {
        return null
      }
    }
  }
}