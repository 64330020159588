module.exports = {
  filter: {
    soldDt: [
      {
        title: 'All',
        value: 'all'
      }, {
        title: 'Last 3 Months',
        value: 'last_3'
      }, {
        title: 'Last 6 Months',
        value: 'last_6'
      }, {
        title: 'Last 12 Months',
        value: 'last_12'
      }
    ],
    salePrice: {
      salesComp: [
        {
          title: 'All',
          value: 'all'
        }, {
          title: 'Under $5M',
          value: 'under_5'
        }, {
          title: '$5M - $10M',
          value: '5_to_10'
        }, {
          title: '$10M - $20M',
          value: '10_to_20'
        }, {
          title: 'Over $20M',
          value: 'over_20'
        }
      ],
      leaseComp: [
        {
          title: 'All',
          value: 'all'
        }, {
          title: 'Under $100k',
          value: 'under_100'
        }, {
          title: '$100k - $250k',
          value: '100_to_250'
        }, {
          title: '$250k - $500k',
          value: '250_to_500'
        }, {
          title: 'Over $500k',
          value: 'over_500'
        }
      ]
    }
  }
}