<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :items="items"
    multiple
    outlined
    hide-details
    placeholder="Type or select"
    item-value="code"
    :menu-props="{
      offsetY: true,
      maxWidth: '100%'
    }"
    :filter="customFilter"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        small
        label
      >
        <v-icon small :color="data.item.fill_color" style="margin-left: 1px;">$mdi-square</v-icon>
        {{ data.item.code }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-item-action>
        <v-checkbox
          :input-value="value.includes(data.item.code)"
          :color="data.item.fill_color"
        ></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.code"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.description" class="zone-description"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState('zoning_and_overlay', [
      'zoneCodes'
    ]),
    ...mapGetters('zoning_and_overlay', ['structuredCodes']),
    items() {
      return this.structuredCodes('zone', 'NSW').map((v) => {
        return {
          code: v.code,
          description: v.desc,
          fill_color: v.fill_color,
          search_str: `${v.code}+++${v.desc}`
        }
      })
    }
  },

  methods: {
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase()

      return item.search_str.toLowerCase().indexOf(searchText) !== -1
    }
  }
}
</script>