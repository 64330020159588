<template>
  <div>
    <v-form ref="form" v-model="isFormValid" @input="$emit('validity-changed', $event)">
      <v-row dense>
        <v-col cols="6">
          <label for="source_type_id" class="caption required">Main Type <span>*</span></label>
          <DropdownSelect
            id="source_type_id"
            v-model="local.source_type_id"
            ky="sourceOfInfos"
            hide-details
            :rules="[$rules.required]"
            @input="handleSourceTypeChange($event)"
          />
        </v-col>
        <!-- Newspaper/Publication -->
        <template v-if="local.source_type_id === 33">
          <v-col cols="6" style="align-self: end;">
            <DropdownSelect
              id="source_subtype_id"
              v-model="local.source_subtype_id"
              ky="newsPublishers"
              hide-details
            />
          </v-col>
          <v-col cols="6">
            <label for="publication_dt" class="caption">Publication Date</label>
            <DatePickerText
              id="publication_dt"
              v-model="local.publication_dt"
              dense
              outlined
              hide-details
            />
          </v-col>
        </template>
        <!-- 3rd Party Data Provider -->
        <template v-else-if="local.source_type_id === 31">
          <v-col cols="6" style="align-self: end;">
            <DropdownSelect
              id="source_subtype_id"
              v-model="local.source_subtype_id"
              ky="thirdPartyData"
              hide-details
            />
          </v-col>
        </template>
        <!-- Advertising Website -->
        <template v-else-if="local.source_type_id === 34">
          <v-col cols="6" style="align-self: end;">
            <DropdownSelect
              id="source_subtype_id"
              v-model="local.source_subtype_id"
              ky="advertisingWebsites"
              hide-details
            />
          </v-col>
        </template>
        <!-- Valuation Report -->
        <template v-else-if="local.source_type_id === 35">
          <v-col cols="6" style="align-self: end;">
            <label for="valuation_report_number" class="caption">Report Number (optional)</label>
            <v-text-field
              id="valuation_report_number"
              v-model="local.valuation_report_number"
              outlined
              dense
              hide-details
            />
          </v-col>
        </template>
        <!-- Other -->
        <template v-else-if="local.source_type_id === 100">
          <v-col cols="6" style="align-self: end;">
            <label for="extra_info" class="caption">Comments (optional)</label>
            <v-textarea
              id="extra_info"
              v-model="local.extra_info"
              outlined
              dense
              hide-details
              rows="3"
            />
          </v-col>
        </template>
        <!-- Not Other -->
        <template v-else-if="local.source_type_id && !([32, 36, 37, 38, 100].includes(local.source_type_id))">
          <v-col cols="12">
            <label for="contact" class="caption">Select a {{ sourceName() }}</label>
            <v-autocomplete
              id="contact"
              v-model="local.contact_id"
              :items="contacts"
              :item-text="
                (item) => item.is_company ? `${item.company_name} (Company)` : `${item.full_name} (Individual)`
              "
              item-value="id"
              outlined
              hide-details
              clearable
              chips
              small-chips
              prepend-inner-icon="$mdi-magnify"
              :loading="loadingContacts"
            >
            </v-autocomplete>
            <div class="text-right">
              <span
                class="primary--text text-decoration-underline cursor-pointer caption"
                @click="handleOpenContactModal"
              >
                Create a New {{ sourceName() }}
              </span>
            </div>
          </v-col>
        </template>
        <v-col cols="12">
          <label for="src_url" class="caption">Source URL (optional)</label>
          <v-text-field
            id="src_url"
            v-model="local.src_url"
            outlined
            dense
            hide-details
            prefix="https://"
            clearable
            clear-icon="$mdi-close"
            :rules="[$rules.website]"
            @keyup.enter="submit"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <label class="caption">Screenshots</label>
          <ImageUpload
            v-model="local.images"
            id="src-of-info-images"
            class="mt-1"
          />
        </v-col>
      </v-row>

      <div v-if="showReviewers" class="mt-2">
        <label for="reviewer_ids" class="caption">Add reviewers (optional)</label>
        <v-autocomplete
          id="reviewer_ids"  
          v-model="local.reviewer_ids"
          :items="reviewers"
          outlined
          dense
          hide-details
          chips
          item-text="username"
          item-value="id"
          multiple
          prepend-inner-icon="$mdi-account-plus"
          @keyup.enter="submit"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="
                const index = local.reviewer_ids.indexOf(data.item.id);
                if (index >= 0) local.reviewer_ids.splice(index, 1);
              "
            >
              <v-avatar
                left
                color="primary"
              >
                <span class="white--text">{{ getInitials(data.item.username) }}</span>
              </v-avatar>
              {{ data.item.username }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-avatar>
              <v-avatar
                color="primary"
              >
                <span class="white--text">{{ getInitials(data.item.username) }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.username"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
    </v-form>

    <v-dialog
      v-model="contactModal"
      width="600"
      scrollable
      persistent
      no-click-animation
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="subtitle-2 font-weight-bold py-2 px-4">Create New {{ sourceName() }}</v-card-title>
        <v-card-text class="mt-3 px-4">
          <ContactForm
            ref="contactForm"
            v-model="contactForm"
            @close="contactModal = false"
            @completed="handleContactCreated"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import api from '@/api'
import { getInitials } from '@/helper/utils'
import {
  defaultContactForm
} from '@/@propedia/default_forms'

import ContactForm from '@/components/forms/ContactForm'
import ImageUpload from '@/components/inputs/ImageUpload'

export default {
  components: {
    ContactForm,
    ImageUpload
  },

  props: ['value', 'loading', 'showReviewers'],

  data() {
    return {
      getInitials,

      isFormValid: true,
      reviewers: [],
      contacts: [],
      contactModal: false,
      contactForm: null,
    }
  },

  computed: {
    ...mapGetters('dropdown', ['dropdownItemName']),
    ...mapState('contact', ['loadingContacts']),
    local() {
      return this.value
    }
  },

  mounted() {
    // this.getReviewers()
    if (this.local.source_type_id) {
      this._getContacts()
    }
  },

  methods: {
    ...mapActions('contact', ['createContact', 'getContacts']),
    update(key, value) {
      this.$emit('input', {
        ...this.local,
        [key]: value
      })
    },
    sourceName() {
      return this.dropdownItemName(this.local.source_type_id, 'sourceOfInfos')
    },
    getReviewers() {
      this.loadingReviewers = true

      api.get('/users/reviewers').then((res) => {
        this.reviewers = res.data.users
      }).finally(() => {
        this.loadingReviewers = false
      })
    },
    async _getContacts() {
      this.contacts = await this.getContacts({
        main_types: [this.sourceName()]
      })
    },
    handleSourceTypeChange() {
      this.update('source_subtype_id', null)

      // TODO: Need to check main type.
      // 3rd party provider, newspaer,.. we don't need contacts
      this._getContacts()
    },
    handleOpenContactModal() {
      this.contactForm = structuredClone({
        ...defaultContactForm,
        main_type: this.sourceName(),
        is_company: false
      }),
      this.contactModal = true
    },
    async handleContactCreated(id) {
      await this._getContacts()

      this.update('contact_id', id)

      this.contactModal = false
    }
  }
}
</script>