export default [
  {
    path: '/account_settings',
    name: 'account.settings',
    component: () => import('@/pages/profile/PasswordReset.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true,
      navigation: 'default'
    }
  }, {
    path: '/account',
    name: 'account',
    component: () => import('@/pages/profile/OrganisationContact.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true,
      navigation: 'default'
    }
  }, {
    path: '/profile/subscription',
    name: 'profiles.subscription',
    component: () => import(/* webpackChunkName: "profiles.subscription" */ '@/pages/profile/SubscriptionPage.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true,
      navigation: 'settings'
    }
  }
]