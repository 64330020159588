<template>
  <div>
    <div v-for="doc in items" :key="doc.id" class="">
      <a :href="doc.path" target="_blank" class="d-flex align-start">
        <v-icon color="primary" style="margin-top: 1px; margin-right: 2px;">{{ fileIcon(doc.type) }}</v-icon>
        <span>{{ doc.label }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters('app', ['fileIcon']),
  },
  
  methods: {
  }
}
</script>