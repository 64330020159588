<template>
  <div :class="{'d-flex': inline}">
    <div
      v-if="label"
      class="caption"
      :class="{'mr-1': inline}"
      :style="`width: ${labelWidth ? labelWidth + 'px' : ''}`"
    >{{ label }}</div>

    <div v-if="typeCodes && typeCodes.length" class="d-flex flex-column gap-column-md" :class="{'mt-n1': inline}">
      <div v-if="state_abbreviation === 'VIC'">
        <div v-if="plain">
          <div v-for="(code, i) in typeCodes" :key="i" class="d-flex flex-nowrap gap-column-md">
            <div v-for="(child, j) in code.children" :key="j" class="d-flex flex-nowrap">
              <div class="d-flex align-center">
                <v-icon small :color="code.fill_color" class="mr-1">$mdi-square</v-icon>
                <span class="caption">{{ child.code }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(code, i) in typeCodes" :key="i" class="my-1">
            <div style="height: 16px;">
              <v-icon small :color="code.fill_color" class="mr-1">$mdi-square</v-icon>
              <span class="caption">{{ code.parent_code }}</span>
            </div>
            <div v-for="(child, j) in code.children" :key="j" class="relative ml-4 children" :class="{'first-child': j === 0}">
              <v-icon small :color="code.fill_color" class="mr-1">$mdi-square</v-icon>
              <span class="caption">{{ child.code }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="state_abbreviation === 'NSW'">
        <div v-for="(code, i) in typeCodes" :key="i" class="d-flex flex-nowrap">
          <div class="d-flex align-center">
            <v-icon small :color="code.fill_color" class="mr-1">$mdi-square</v-icon>
            <span class="caption">{{ code.code }}</span>
          </div>
        </div>
      </div>
      <div v-else-if="state_abbreviation === 'QLD'">
        <div v-for="(code, i) in typeCodes" :key="i" class="d-flex flex-nowrap">
          <div class="d-flex align-center">
            <v-icon small :color="code.fill_color" class="mr-1">$mdi-square</v-icon>
            <span class="caption">{{ code.code }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    codes: {
      type: Array,
      default: () => null
    },
    type: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    plain: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String
    },
    state_abbreviation: {
      type: String,
      required: true
    },
    addresses: {
      type: Array,
      default: () => null
    }
  },

  data() {
    return {
      locCodes: []
    }
  },

  computed: {
    ...mapGetters('zoning_and_overlay', [
      'structuredCodes'
    ]),
    typeCodes() {
      if (this.addresses) {
        return this.structuredCodes(this.type, this.state_abbreviation, this.locCodes)
      } else {
        return this.structuredCodes(this.type, this.state_abbreviation, this.codes)
      }
    }
  },

  watch: {
    addresses(v) {
      v && this.getCodes()
    }
  },

  mounted() {
    this.addresses && this.addresses.length && this.getCodes()
  },

  methods: {
    ...mapActions('zoning_and_overlay', [
      'getCoverageCodes'
    ]),
    // getParsedCodes(codes) {
    //   if (codes) {
    //     if (this.state_abbreviation === 'VIC') {
    //       // Group codes by codeparent
    //       const res = codes.reduce((r, a) => {
    //         const parent = r.find((v) => v.name === a[1])
  
    //         if (parent) {
    //           if (!parent.children.find((v) => v.name === a[0])) {
    //             parent.children.push({ name: a[0] })
    //           }
    //         } else {
    //           r.push({
    //             name: a[1],
    //             color: this.getZoneByCode(this.type, a[1], this.state_abbreviation)?.fill_color,
    //             children: [{
    //               name: a[0]
    //             }]
    //           })
    //         }
  
    //         return r
    //       }, []);
  
    //       return res
    //     } else if (this.state_abbreviation === 'NSW') {
    //       const uniqueCodes = uniqueValues(codes)

    //       return uniqueCodes.map((v) => {
    //         const f = this.getZoneByCode(this.type, v, this.state_abbreviation)

    //         return {
    //           name: v,
    //           color: f?.fill_color
    //         }
    //       })
    //     }
    //   } else {
    //     return null
    //   }
    // },
    getCodes() {
      this.getCoverageCodes({
        type: this.type,
        gnaf_property_pids: this.addresses.map((v) => v.gnaf_property_pid),
        state_abbreviation: this.state_abbreviation
      }).then((res) => {
        if (this.state_abbreviation === 'VIC') {
          this.locCodes = res.data.items.map((v) => v[0])
        } else if (this.state_abbreviation === 'NSW') {
          this.locCodes = res.data.items
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.children {
  height: 16px;
  &:before {
    left: -8px;
    top: -4px;
    width: 10px;
    height: 17px;
    content: "";
    position: absolute;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 0 0 0 0px;
  }
}
.first-child:before {
  height: 10px;
  top: 4px;
}
</style>