<template>
  <v-form>
    <v-row v-if="state_abbreviation === 'VIC'" dense>
      <v-col cols="6">
        <label class="caption">Zone</label>
        <ZoneVicMultiSelector
          v-model="local.zone_codes"
          type="zone"
        />
      </v-col>
      <v-col cols="6">
        <label class="caption">Overlay</label>
        <ZoneVicMultiSelector
          v-model="local.overlay_codes"
          type="overlay"
        />
      </v-col>
    </v-row>
    <v-row v-else-if="state_abbreviation === 'QLD'" dense>
      <v-col cols="6">
        <label class="caption">Zone</label>
        <ZoneOverlayMultiSelector
          v-model="local.zone_codes"
          type="zone"
          :state_abbreviation="state_abbreviation"
          :loading="loadingZone"
        />
      </v-col>
    </v-row>
    <div v-else-if="state_abbreviation === 'NSW'">
      <v-row dense>
        <v-col cols="8">
          <label class="caption">Zone</label>
          <ZoneNswMultiSelector
            v-model="local.zone_codes"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <label for="fsr" class="caption">FSR</label>
          <div class="d-flex align-center">
            <CurrencyText
              id="fsr"
              v-model="local.fsr"
              hide-details
              :has-unit="false"
            />
            <span class="ml-1 white-space-no-wrap">: 1</span>
          </div>
        </v-col>
        <v-col cols="4">
          <label for="lot_size" class="caption">Lot Size</label>
          <CurrencyText
            id="lot_size"
            v-model="local.lot_size"
            hide-details
            :units="['SQM', 'HA']"
          />
        </v-col>
        <v-col cols="5">
          <label for="flood_zone_type_id" class="caption">Flood Zone</label>
          <DropdownSelect
            id="flood_zone_type_id"
            v-model="local.flood_zone_type_id"
            ky="floodZoneTypes"
            :clearable="false"
            hide-details
          />
        </v-col>
      </v-row>
    </div>
    <v-row dense>
      <v-col cols="12" class="d-flex mb-n2">
        <div class="caption" style="width: 188px;">Volume</div>
        <div class="caption" style="width: 160px;">Folio</div>
      </v-col>
      <v-col
        v-for="(volume_folio, i) in value.volume_folios"
        :key="i"
        cols="12"
        class="d-flex align-center"
      >
        <div style="max-width: 160px;">
          <v-text-field
            v-model="volume_folio.volume"
            outlined
            hide-details
          ></v-text-field>
        </div>
        <span style="
          margin-left: 10px;
          margin-right: 10px;
          font-size: 20px;
        ">/</span>
        <div style="max-width: 160px;">
          <v-text-field
            v-model="volume_folio.folio"
            outlined
            hide-details
          ></v-text-field>
        </div>
        <div class="ml-2 d-flex gap-md">
          <v-icon v-if="i < value.volume_folios.length - 1" @click="removeVolumeFolio()">$mdi-trash-can</v-icon>
          <v-icon v-if="i === value.volume_folios.length - 1" @click="handleAddVolumeFolio()">$mdi-plus</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="d-flex mb-n2">
        <div class="caption" style="width: 148px;">Lot</div>
        <div class="caption" style="width: 166px;">Section</div>
        <div class="caption" style="width: 160px;">Plan</div>
      </v-col>
      <v-col
        v-for="(lot_section_plan, i) in local.lot_section_plans"
        :key="i"
        cols="12"
        class="d-flex align-center"
      >
        <v-text-field
          v-model="lot_section_plan.lot"
          outlined
          hide-details
          style="max-width: 120px;"
        ></v-text-field>
        <span style="
          margin-left: 10px;
          margin-right: 10px;
          font-size: 20px;
        ">/</span>
        <v-text-field
          v-model="lot_section_plan.section"
          outlined
          hide-details
          style="max-width: 140px;"
        ></v-text-field>
        <span style="
          margin-left: 10px;
          margin-right: 10px;
          font-size: 20px;
        ">/</span>
        <v-text-field
          v-model="lot_section_plan.plan"
          outlined
          hide-details
          style="max-width: 160px;"
        ></v-text-field>

        <div class="ml-2 d-flex gap-md">
          <v-icon v-if="i < value.lot_section_plans.length - 1" @click="removeLotSectionPlan()">$mdi-trash-can</v-icon>
          <v-icon v-if="i === value.lot_section_plans.length - 1" @click="handleAddLotSectionPlan()">$mdi-plus</v-icon>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import arrayHelper from '@/helper/array'

import ZoneOverlayMultiSelector from '@/components/inputs/ZoneOverlayMultiSelector'
import ZoneVicMultiSelector from '@/components/inputs/ZoneVicMultiSelector'
import ZoneNswMultiSelector from '@/components/inputs/ZoneNswMultiSelector'

export default {
  props: [
    'value',
    'state_abbreviation'
  ],
  
  components: {
    ZoneOverlayMultiSelector,
    ZoneVicMultiSelector,
    ZoneNswMultiSelector
  },

  data() {
    return {
      loadingZone: false,
      loadingOverlay: false
    }
  },

  computed: {
    local() {
      return this.value
    }
  },

  methods: {
    handleAddVolumeFolio(address_detail_pid = null) {
      if (!address_detail_pid) { // Single address sale
        this.$emit('input', {
          ...this.value,
          volume_folios: [
            ...this.value.volume_folios,
            {
              volume: null,
              folio: null
            }
          ]
        })
      }
    },
    removeVolumeFolio(i) {
      this.$emit('input', {
        ...this.value,
        volume_folios: arrayHelper.removeItemAtIndex(this.value.volume_folios, i)
      })
    },
    handleAddLotSectionPlan(address_detail_pid = null) {
      if (!address_detail_pid) { // Single address sale
        this.$emit('input', {
          ...this.value,
          lot_section_plans: [
            ...this.value.lot_section_plans,
            {
              lot: null,
              section: null,
              plan: null
            }
          ]
        })
      }
    },
    removeLotSectionPlan(i) {
      this.$emit('input', {
        ...this.value,
        lot_section_plans: arrayHelper.removeItemAtIndex(this.value.lot_section_plans, i)
      })
    },
  }
}
</script>