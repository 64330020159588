<template>
  <div v-if="pageCount > 1" class="d-flex flex-grow-1 justify-space-between align-center py-2">
    <div v-if="showOptions">{{ (value - 1) * itemsPerPage + 1 }}-{{ value * itemsPerPage > total ? total : value * itemsPerPage }} of {{ total }}</div>
    <v-pagination
      :value="value"
      :length="pageCount"
      v-bind="$attrs"
      class="flex-grow-1"
      @input="$emit('input', $event)"
    ></v-pagination>
    <div v-if="showOptions" class="d-flex align-center justify-end">
      <span class="mr-2">Rows Per page: </span>
      <v-select
        :value="itemsPerPage"
        dense
        hide-details
        outlined
        :items="[10, 25, 50, 100]"
        style="max-width: 84px;"
        @change="$emit('input-items-per-page', $event)"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 1,
      type: Number
    },
    itemsPerPage: {
      default: 10,
      required: false,
      type: Number
    },
    total: {
      default: 0,
      required: false,
      type: Number
    },
    fetchFunction: {
      type: Function
    },
    showOptions: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    pageCount() {
      return Math.ceil(this.total / this.itemsPerPage)
    }
  }
}
</script>