import api from '@/api'

export default {
  namespaced: true,

  state: {
  },

  mutations: {
  },

  actions: {
    getFsr: (_, {
      gnaf_property_pids
    }) => {
      return api.get('/gis/fsr_coverage', {
        params: {
          gnaf_property_pids
        }
      })
    },
    getLotSize: (_, {
      gnaf_property_pids
    }) => {
      return api.get('/gis/lot_size_coverage', {
        params: {
          gnaf_property_pids
        }
      })
    },
    getLotSectionPlans: (_, {
      gnaf_property_pids,
      state_abbreviation
    }) => {
      return api.get('/gis/lot_section_plans', {
        params: {
          gnaf_property_pids,
          state_abbreviation
        }
      })
    },
    getHob: (_, {
      gnaf_property_pids
    }) => {
      return api.get('/gis/hob_coverage', {
        params: {
          gnaf_property_pids
        }
      })
    },
    getEasement: (_, {
      gnaf_property_pids,
      state_abbreviation
    }) => {
      return api.get('/gis/is_easement', {
        params: {
          gnaf_property_pids,
          state_abbreviation
        }
      })
    },
    getFlood: (_, {
      gnaf_property_pids
    }) => {
      return api.get('/gis/is_flood', {
        params: {
          gnaf_property_pids
        }
      })
    }
  },

  getters: {
  }
}