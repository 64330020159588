<template>
  <div class="buttons-container">
    <v-tooltip
      right
      v-for="(m, i) in modes"
      :key="i"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          v-if="m.badge"
          color="primary"
          :content="formattedTotal"
          overlap
          offset-x="14"
          offset-y="24"
        >
          <v-btn
            fab
            small
            elevation="1"
            class="mt-2"
            v-bind="attrs"
            v-on="on"
            @click="handleBtnClick(m)"
          >
            <v-icon v-if="value === m.val">$mdi-close</v-icon>
            <v-icon v-else>{{ m.icon }}</v-icon>
          </v-btn>
        </v-badge>
        <v-btn
          v-else
          fab
          small
          elevation="1"
          class="mt-2"
          v-bind="attrs"
          v-on="on"
          @click="handleBtnClick(m)"
        >
          <v-icon v-if="value === m.val">$mdi-close</v-icon>
          <v-icon v-else>{{ m.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ m.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['value'],

  data() {
    return {
      modes: [
        {
          val: 'search',
          icon: '$mdi-map-search',
          tooltip: 'Filter'
        }, {
          val: 'properties',
          icon: '$mdi-view-list',
          tooltip: 'Search Properties',
          badge: true
        },
        // {
        //   val: 'my-comps',
        //   icon: '$mdi-file-star',
        //   tooltip: 'My Comps'
        // }, {
        //   val: 'reporting',
        //   icon: '$mdi-printer-pos',
        //   tooltip: 'Reporting'
        // }
      ]
    }
  },

  computed: {
    ...mapState('property', ['total']),
    formattedTotal() {
      return this.formatNumber(this.total);
    }
  },
  
  methods: {
    handleBtnClick(mode) {
      if (this.value) {
        this.$emit('input', this.value === mode.val ? null : mode.val)
      } else {
        this.$emit('input', mode.val)
      }
    },
    formatNumber(num) {
      if (num >= 1000) {
        return `${Math.floor(num / 1000)}k+`;
      }
      return num.toString();
    }
  }
}
</script>

<style scoped lang="scss">
  .buttons-container {
    position: absolute;
    top: 50px;
    right: -20px;
    transform: translate(75%, -50%);
    display: flex;
    flex-direction: column;
  }
</style>