<template>
  <v-menu offset-y left z-index="1001">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        class="ml-4"
        size="36"
        color="primary"
        v-bind="attrs" v-on="on"
      >
        <span class="white--text">{{ getInitials(fullName) }}</span>
      </v-avatar>
    </template>
    <v-card width="320">
      <v-card-text class="d-flex gap-column-md">
        <v-avatar
          color="primary"
          size="52"
        ><span class="white--text text-h5">{{ getInitials(fullName) }}</span></v-avatar>
        <div>
          <div class="font-weight-bold">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div class="caption">{{ user.email }}</div>
          <div v-if="!isAdmin" class="">{{ user.company?.name }}</div>
        </div>
      </v-card-text>

      <v-divider v-if="!isAdmin"></v-divider>

      <v-card-text v-if="!isAdmin" class="d-flex align-center">
        <span>Current subscription plan:</span>
        <v-chip label small color="primary" class="ml-2">{{ user.plan.name }}</v-chip>
      </v-card-text>

      <v-divider></v-divider>

      <v-list dense nav>
        <template v-for="item in listItems">
          <v-list-item
            v-if="item.for.includes(userRole)"
            :key="item.text"
            :to="item.to"
            exact
            active-class="primary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>$mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getInitials } from '@/helper/utils'

export default {
  props: ['dark'],
  data() {
    return {
      getInitials
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isAdmin', 'userRole', 'fullName']),
    listItems() {
      return [
        {
          to: '/admin/dashboard',
          icon: '$mdi-account-tie',
          text: 'Admin',
          for: ['ADMIN']
        }, {
          to: '/dev/server/resources',
          icon: '$mdi-account-tie',
          text: 'Dev Panel',
          for: ['DEV']
        },
        // {
        //   to: '/profile',
        //   icon: '$mdi-account',
        //   text: 'Profile',
        //   for: ['USER', 'COMPANY', 'ADMIN']
        // },
        {
          to: '/account_settings',
          icon: '$mdi-account-cog',
          text: 'Account Settings',
          for: ['USER', 'COMPANY', 'ADMIN']
        }, {
          to: '/dashboard',
          icon: '$mdi-home-city',
          text: 'Dashboard',
          for: ['USER', 'COMPANY']
        }
      ]
    }
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>
