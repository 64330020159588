module.exports = {
  gnafTypes: {
    vm: {
      parcelLayer: 'parcel_view_vic',
      // propertyLayer: 'vm_property_view'
    },
    gs: {
      // parcelLayer: 'gs_parcel_view',
      // propertyLayer: 'gs_properties'
    }
  },

  salesTypeValues: {
    salesComp: {
      compIndexLayer: 'comps',
      color: '#E43A54',
      label: 'Sold',
      markerFile: 'sales',
      shortlistMarkerFile: 'shortlist_sales'
    },
    leaseComp: {
      compIndexLayer: 'lease_comps',
      color: '#FF9800',
      label: 'Leased',
      markerFile: 'lease',
      shortlistMarkerFile: 'shortlist_lease'
    }
  },

  attributeGroups: {
    ADDRESS: 0,
    SALES: 1,
    BUILDING: 2,
    LAND: 3,
    CAMPAIGN: 4,
    ATTACHMENT: 5
  }
}