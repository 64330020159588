<template>
  <div>
    <div v-if="showAddressSearch" class="px-6 my-4">
      <SuburbAutoComplete
        v-model="local.address"
        clearable
        outlined
        dense
        hide-details
        placeholder="e.g, 146 YARRA STREET ABBOTSFORD VIC 3067"
        class="mt-2"
        @input="update('address', $event)"
      />
    </div>
    <v-expansion-panels v-model="panel" accordion multiple flat class="mb-12" tile>
      <!-- <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">Drawn Area</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-chip label close>
            <v-icon left>$mdi-vector-polygon</v-icon>
            Drawn Polygon Area
          </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel> -->
      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          <span style="line-height: 1.2rem;" class="flex-grow-0">Property Types</span>
          <v-chip v-if="typesPrefix" small label class="ml-1 flex-grow-0">{{ typesPrefix }}</v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <PropertyTypeMultiSelect
            v-model="local.typeIds"
            dense
            outlined
            hide-details
            :limited="true"
            :modal-browsing="false"
            @input="update('typeIds', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          Existing Rights of Transfer
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group
            :value="local.existing_rights_of_transfer_id"
            hide-details
            class="mt-0"
            @change="update('existing_rights_of_transfer_id', $event)"
          >
            <v-radio
              label="All"
              value="All"
            ></v-radio>  
            <v-radio
              v-for="(item, id) in dropdowns.existingRightsOfTransfers"
              :key="id"
              :label="item.name"
              :value="item.id"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          Single / Multi Property {{ salesType === 'salesComp' ? 'Sale' : 'Lease' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group
            v-model="local.is_multi_sale"
            hide-details
            class="mt-0"
            @change="update('is_multi_sale', $event)"
          >
            <v-radio
              label="All"
              value="All"
            ></v-radio>
            <v-radio
              :label="`Single Property ${salesType === 'salesComp' ? 'Sale' : 'Lease'}`"
              value="Single"
            ></v-radio>
            <v-radio
              :label="`Multi Property ${salesType === 'salesComp' ? 'Sale' : 'Lease'}`"
              value="Multi"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="salesType === 'salesComp'" style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          Tenanted Investment
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group
            :value="local.tenure_type_id"
            hide-details
            class="mt-0"
            @change="update('tenure_type_id', $event)"
          >
            <v-radio
              label="All"
              value="All"
            ></v-radio>
            <v-radio
              label="Vacant Possession"
              :value="1"
            ></v-radio>
            <v-radio
              label="Tenanted Investment"
              :value="2"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          <span class="flex-grow-0">{{ datePrefix }}</span>
          <span v-if="dateSuffix" class="ml-1 caption primary--text" style="line-height: 15px;">({{ dateSuffix }})</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <DateRangeSearch
            v-model="local.dateRange"
            @input="update('dateRange', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          <span class="flex-grow-0">{{ pricePrefix }}</span>
          <span v-if="priceSuffix" class="ml-1 caption primary--text" style="line-height: 15px;">({{ priceSuffix }})</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <PriceRangeSearch
            v-model="local.priceRange"
            @input="update('priceRange', $event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          <span class="flex-grow-0">
            Lettable Area
          </span>
          <span v-if="buildingSizeSuffix" class="ml-2 caption primary--text" style="line-height: 15px;">{{ buildingSizeSuffix }} SQM</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <label for="building-size-min" class="caption">Min</label>
              <CurrencyText
                id="building-size-min"
                v-model="local.buildingSizeRange.from"
                bouncing-time="500"
                hide-details
                @debounced="update('buildingSizeRange', {
                  ...local.buildingSizeRange,
                  from: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
            <v-col cols="12">
              <label for="building-size-max" class="caption">Max</label>
              <CurrencyText
                id="building-size-max"
                v-model="local.buildingSizeRange.to"
                bouncing-time="500"
                hide-details
                @debounced="update('buildingSizeRange', {
                  ...local.buildingSizeRange,
                  to: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          <span class="flex-grow-0">Land Area</span>
          <span v-if="landSizeSuffix" class="ml-2 caption primary--text" style="line-height: 15px;">{{ landSizeSuffix }} SQM</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <label for="land-size-min" class="caption">Min</label>
              <CurrencyText
                id="land-size-min"
                v-model="local.landSizeRange.from"
                bouncing-time="500"
                hide-details
                @debounced="update('landSizeRange', {
                  ...local.landSizeRange,
                  from: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
            <v-col cols="12">
              <label for="land-size-max" class="caption">Max</label>
              <CurrencyText
                id="land-size-max"
                v-model="local.landSizeRange.to"
                bouncing-time="500"
                hide-details
                @debounced="update('landSizeRange', {
                  ...local.landSizeRange,
                  to: $event
                })"
                :units="dropdowns.areaUnits"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">Zone</span>
          <v-chip v-if="zonePrefix" small label class="ml-1 flex-grow-0">{{ zonePrefix }}</v-chip>
          <span v-if="zonePrefix" class="ml-1 caption primary--text">{{ zoneSuffix }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <label class="caption">VIC</label>
              <ZoneVicMultiSelector
                v-model="local.zoneCodes.vic"
                type="zone"
                @input="$emit('input', {
                  ...local,
                  zoneCodes: {
                    ...local.zoneCodes,
                    vic: $event
                  }
                })"
              />
            </v-col>
            <v-col cols="12">
              <label class="caption">NSW</label>
              <ZoneNswMultiSelector
                v-model="local.zoneCodes.nsw"
                @input="$emit('input', {
                  ...local,
                  zoneCodes: {
                    ...local.zoneCodes,
                    nsw: $event
                  }
                })"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel style="border-bottom: solid 1px #e0e0e0;">
        <v-expansion-panel-header>
          <span class="flex-grow-0" style="line-height: 1.2rem;">Overlay</span>
          <v-chip v-if="zonePrefix" small label class="ml-1 flex-grow-0">{{ zonePrefix }}</v-chip>
          <span v-if="zonePrefix" class="ml-1 caption primary--text">{{ zoneSuffix }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <label class="caption">VIC</label>
              <ZoneVicMultiSelector
                v-model="local.overlayCodes"
                type="overlay"
                @input="$emit('input', {
                  ...local,
                  overlayCodes: $event
                })"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import options from '@/@propedia/constants/options'

// Components
import SuburbAutoComplete from '@/components/SuburbAutoComplete'
import PropertyTypeMultiSelect from '@/components/PropertyTypeMultiSelect'
import DateRangeSearch from '@/components/inputs/search/DateRangeSearch'
import PriceRangeSearch from '@/components/inputs/search/PriceRangeSearch'
import ZoneVicMultiSelector from '@/components/inputs/ZoneVicMultiSelector'
import ZoneNswMultiSelector from '@/components/inputs/ZoneNswMultiSelector'
import CurrencyText from '@/components/inputs/CurrencyText'

export default {
  components: {
    SuburbAutoComplete,
    PropertyTypeMultiSelect,
    DateRangeSearch,
    PriceRangeSearch,
    ZoneVicMultiSelector,
    ZoneNswMultiSelector,
    CurrencyText
  },
  
  props: {
    value: {
      type: Object
    },
    showAddressSearch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      panel: [0]
    }
  },

  computed: {
    ...mapState('dropdown', ['dropdowns']),
    ...mapGetters('zoning_and_overlay', [
      'structuredCodes'
    ]),
    local() {
      return this.value
    },
    salesType() {
      return this.$route.query.sales_type
    },
    typesPrefix() {
      return this.local.typeIds.length
    },
    datePrefix() {
      return this.salesType === 'salesComp' ? 'Sale Date' : 'Commencement Date'
    },
    dateSuffix() {
      if (!this.local.dateRange) return null

      switch (this.local.dateRange.value) {
        case 'last_3':
          return 'Last 3 Month'
        case 'last_6':
          return 'Last 6 Month'
        case 'last_12':
          return 'Last 12 Month'
        case 'custom':
          return `${this.local.dateRange.from_dt || ''} - ${this.local.dateRange.to_dt || ''}`
        default:
          return null
      }
    },
    pricePrefix() {
      return this.salesType === 'salesComp' ? 'Sale Price' : 'Commencing Rent'
    },
    priceSuffix() {
      if (!this.local.priceRange) return null

      const priceOption = options.filter.salePrice[this.salesType].find((v) => v.value === this.local.priceRange.value)

      if (priceOption) {
        if (priceOption.value === 'custom') {
          return `${this.local.priceRange.from_price || ''} - ${this.local.priceRange.to_price || ''}`
        } else if (priceOption.value !== 'all') {
          return priceOption.title
        }
      }

      return null
    },
    buildingSizeSuffix() {
      if (this.local.buildingSizeRange.from.value && this.local.buildingSizeRange.to.value) {
        return `${this.local.buildingSizeRange.from.value} - ${this.local.buildingSizeRange.to.value}`
      } else if (!this.local.buildingSizeRange.from.value && this.local.buildingSizeRange.to.value) {
        return `< ${this.local.buildingSizeRange.to.value}`
      } else if (this.local.buildingSizeRange.from.value && !this.local.buildingSizeRange.to.value) {
        return `> ${this.local.buildingSizeRange.from.value}`
      }

      return null
    },
    landSizeSuffix() {
      if (this.local.landSizeRange.from.value && this.local.landSizeRange.to.value) {
        return `${this.local.landSizeRange.from.value} - ${this.local.landSizeRange.to.value}`
      } else if (!this.local.landSizeRange.from.value && this.local.landSizeRange.to.value) {
        return `< ${this.local.landSizeRange.to.value}`
      } else if (this.local.landSizeRange.from.value && !this.local.landSizeRange.to.value) {
        return `> ${this.local.landSizeRange.from.value}`
      }

      return null
    },
    zoneOverlayCodes() {
      return []
      // return [...(this.local.zoneCodes ?? []), ...(this.local.overlayCodes ?? [])]
    },
    zonePrefix() {
      return null
      // return this.zoneOverlayCodes.length ? this.zoneOverlayCodes[0] : null
    },
    zoneSuffix() {
      return null
      // const len = this.zoneOverlayCodes.length

      // if (len > 1) {
      //   return `and ${len - 1} more`
      // } else {
      //   return null
      // }
    }
  },

  methods: {
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value })
    }
  }
}
</script>