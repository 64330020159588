export default [
  {
    path: '/auth/signup/company',
    name: 'auth.signup.company',
    component: () => import('@/pages/auth/SignupCompany.vue'),
    meta: {
      // layout: 'auth',
      requiresGuest: true
    }
  }, {
    path: '/auth/signin',
    name: 'auth.signin',
    component: () => import('@/pages/auth/SigninPage.vue'),
    meta: {
      // layout: 'auth',
      requiresGuest: true
    }
  }, {
    path: '/auth/forgot-password',
    name: 'auth.forgot-password',
    component: () => import('@/pages/auth/ForgotPassword.vue'),
    meta: {
      // layout: 'auth',
      requiresGuest: true
    }
  }, {
    path: '/auth/reset-password',
    name: 'auth.reset-password',
    component: () => import('@/pages/auth/ResetPassword.vue'),
    meta: {
      // layout: 'auth',
      requiresGuest: true
    }
  }
]