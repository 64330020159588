<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :items="items"
    multiple
    outlined
    hide-details
    placeholder="Type or select"
    item-value="code"
    :menu-props="{
      offsetY: true,
      maxWidth: '100%'
    }"
    :filter="customFilter"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        small
        label
      >
        <v-icon small :color="data.item.fill_color" style="margin-left: 1px;">$mdi-square</v-icon>
        {{ data.item.code }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="data.item.parent_code">
        <v-list-item
          @mousedown.prevent
          @click="toggle(data.item.parent_code)"
        >
          <div class="d-flex align-center" :style="`
            width: 8px;
            height: 28px;
            background-color: ${data.item.fill_color};
            margin-right: 10px;
            margin-left: -16px;
          `">
          </div>
          <v-list-item-action style="margin-right: 2px;">
            <v-icon size="20" :color="data.item.fill_color">
              {{ icon(data.item.parent_code) }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content v-text="data.item.parent_code"></v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item-action>
          <v-checkbox
            :input-value="value.includes(data.item.code)"
            :color="data.item.fill_color"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.code"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.description" class="zone-description"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mergeArrayWithoutDuplicate, removeMultipleFromArray } from '@/helper/array'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => 'VIC'
    }
  },

  computed: {
    ...mapState('zoning_and_overlay', [
      'zoneCodes',
      'overlayCodes'
    ]),
    ...mapGetters('zoning_and_overlay', ['structuredCodes']),
    items() {
      let ret = []

      this.structuredCodes(this.type, 'VIC').forEach((v) => {
        ret.push({
          parent_code: v.parent_code,
          fill_color: v.children[0].fill_color,
          search_str: `${v.parent_code}+++${v.children.map((c) => `${c.code}+++${c.description}`).join('+++')}`
        })

        v.children.forEach((vv) => {
          ret.push({
            ...vv,
            search_str: `${vv.code}+++${vv.description}`
          })
        })
      })

      return ret
    }
  },

  methods: {
    getParentByCode(parent_code) {
      const parent = this.structuredCodes(this.type, 'VIC').find((v) => v.parent_code === parent_code)

      return parent
    },
    isAllChildrenSelected(parent_code) {
      const parent = this.getParentByCode(parent_code)

      return parent.children.every((v) => this.value.includes(v.code))
    },
    isNoChildrenSelected(parent_code) {
      const parent = this.getParentByCode(parent_code)

      return parent.children.every((v) => !this.value.includes(v.code))
    },
    icon(parent_code) {
      if (this.isAllChildrenSelected(parent_code)) {
        return '$mdi-checkbox-marked'
      } else if (this.isNoChildrenSelected(parent_code)) {
        return '$mdi-checkbox-blank-outline'
      } else {
        return '$mdi-minus-box'
      }
    },
    toggle(parent_code) {
      const parent = this.getParentByCode(parent_code)
      const children_codes = parent.children.map((v) => v.code)
      const value_copied = [...this.value]

      this.$nextTick(() => {
        if (this.isAllChildrenSelected(parent_code)) {
          removeMultipleFromArray(value_copied, children_codes)
        } else {
          mergeArrayWithoutDuplicate(value_copied, children_codes)
        }

        this.$emit('input', value_copied)
      })
    },
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase()

      return item.search_str.toLowerCase().indexOf(searchText) !== -1
    }
  }
}
</script>