var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',_vm._b({attrs:{"value":_vm.value,"items":_vm.items,"multiple":"","outlined":"","hide-details":"","placeholder":"Type or select","item-value":"code","menu-props":{
    offsetY: true,
    maxWidth: '100%'
  },"filter":_vm.customFilter},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"small":"","label":""}},'v-chip',data.attrs,false),[_c('v-icon',{staticStyle:{"margin-left":"1px"},attrs:{"small":"","color":data.item.fill_color}},[_vm._v("$mdi-square")]),_vm._v(" "+_vm._s(data.item.code)+" ")],1)]}},{key:"item",fn:function(data){return [(data.item.parent_code)?[_c('v-list-item',{on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.toggle(data.item.parent_code)}}},[_c('div',{staticClass:"d-flex align-center",style:(`
          width: 8px;
          height: 28px;
          background-color: ${data.item.fill_color};
          margin-right: 10px;
          margin-left: -16px;
        `)}),_c('v-list-item-action',{staticStyle:{"margin-right":"2px"}},[_c('v-icon',{attrs:{"size":"20","color":data.item.fill_color}},[_vm._v(" "+_vm._s(_vm.icon(data.item.parent_code))+" ")])],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item.parent_code)}})],1)]:[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.value.includes(data.item.code),"color":data.item.fill_color}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.code)}}),_c('v-list-item-subtitle',{staticClass:"zone-description",domProps:{"innerHTML":_vm._s(data.item.description)}})],1)]]}}])},'v-autocomplete',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }