<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :items="items"
    multiple
    outlined
    hide-details
    placeholder="Type or select"
    item-value="code"
    :menu-props="{
      offsetY: true,
      maxWidth: '100%'
    }"
    :filter="customFilter"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        small
        label
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-icon small :color="data.item.fill_color" style="margin-left: 1px;">$mdi-square</v-icon>
        {{ data.item.code }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="data.item.parent_code">
        <v-list-item disabled>
          <div class="d-flex align-center" :style="`
            width: 8px;
            height: 38px;
            background-color: ${data.item.fill_color};
            margin-right: 12px;
            margin-left: -16px;
          `">
          </div>
          <v-list-item-content v-text="data.item.parent_code"></v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item-action>
          <v-checkbox
            :input-value="value.includes(data.item.code)"
            :color="data.item.fill_color"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.code"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.description" class="zone-description"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'zone'
    },
    state_abbreviation: {
      type: String,
      default: 'VIC'
    }
  },

  computed: {
    ...mapState('zoning_and_overlay', [
      'zoneCodes',
      'overlayCodes'
    ]),
    ...mapGetters('zoning_and_overlay', ['structuredCodes']),
    items() {
      let ret = []

      this.structuredCodes(this.type, this.state_abbreviation).forEach((v) => {
        ret.push({
          parent_code: v.parent_code,
          fill_color: v.children[0].fill_color
        })

        v.children.forEach((vv) => {
          ret.push(vv)
        })
      })

      return ret
    }
  },

  methods: {
    remove(item) {
      this.$emit('input', this.value.filter((v) => v !== item.code))
    },
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase()

      if (this.state_abbreviation === 'QLD') {
        if (item.code) {
          return item.code.toLowerCase().indexOf(searchText) !== -1
        } else {
          return false
        }
      } else {
        if (item.code && item.description) {
          return item.code.toLowerCase().indexOf(searchText) !== -1 || item.description.toLowerCase().indexOf(searchText) !== -1
        } else {
          return false
        }
      }
    }
  }
}
</script>