import axios from 'axios'
import store from './store'

const API = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_SERVER_API_ENDPOINT || '/localhost:3000',
  timeout: 20000
})

API.interceptors.request.use(async (config) => {
  if (store.state.auth.token) {
    config.headers = {
      'Authorization': `Bearer ${store.state.auth.token}`,
      ...config.headers
    }
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

API.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (axios.isCancel(error)) {
    // Cancelled
  } else {
    const code = error.response?.status ?? 0
    // const url = error.config.url ?? '?'

    if (code == 401) {
      window.location = '/auth/signin'
    }
    if (code === 503) {
      window.location = '/maintenance'
    }

    console.error(error.response?.status, error.message)
  }

  return Promise.reject(error)
})

export default API
