export default [
  {
    path: '/dev/dashboard',
    name: 'dev.dashboard',
    component: () => import('@/pages/dev/Dashboard.vue'),
    meta: {
      layout: 'dev',
      requiresDev: true
    }
  }, {
    path: '/dev/requests',
    name: 'dev.requests',
    component: () => import('@/pages/dev/Requests.vue'),
    meta: {
      layout: 'dev',
      requiresDev: true
    }
  }, {
    path: '/dev/settings',
    name: 'dev.settings',
    component: () => import('@/pages/dev/Settings.vue'),
    meta: {
      layout: 'dev',
      requiresDev: true
    }
  }
]