<template>
  <div :style="customStyle">
    <v-carousel
      v-if="items?.length"
      :height="height"
      hide-delimiter-background
      show-arrows-on-hover
      :show-arrows="items.length > 1"
    >
      <v-carousel-item
        v-for="item in items"
        :key="item.id"
        :src="item.path"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <div v-else class="mt-1 mb-2 d-flex justify-center align-center" :style="customStyle">
      No Image
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: '240'
    },
    width: {
      type: String
    }
  },
  computed: {
    customStyle() {
      return {
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  }
}
</script>