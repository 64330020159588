import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import './plugins/rules'
import './plugins/cookies'
import './plugins/filter'
import './plugins/icons'

import './scss/index.scss'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { VueMaskDirective } from 'v-mask'

import Loader from './components/Loader.vue'
import ExpandPanel from './components/ExpandPanel.vue'
import CurrencyText from '@/components/inputs/CurrencyText.vue'
import ToggleInput from '@/components/inputs/ToggleInput.vue'
import DatePickerText from '@/components/inputs/DatePickerText.vue'
import PhoneText from '@/components/inputs/PhoneText.vue'
import DropdownSelect from '@/components/inputs/DropdownSelect.vue'
import BtnSelection from '@/components/inputs/BtnSelection.vue'

import NormalField from '@/components/attributes/NormalField.vue'
import YesNoField from '@/components/attributes/YesNoField'
import PropertyTypeField from '@/components/attributes/PropertyType'
import ZoneOverlayField from '@/components/attributes/ZoneOverlay'
import MultiField from '@/components/attributes/MultiField'

import ConfirmModal from '@/components/modals/ConfirmModal'

Vue.component('Loader', Loader)
Vue.component('ExpandPanel', ExpandPanel)
Vue.component('CurrencyText', CurrencyText)
Vue.component('ToggleInput', ToggleInput)
Vue.component('DatePickerText', DatePickerText)
Vue.component('PhoneText', PhoneText)
Vue.component('DropdownSelect', DropdownSelect)
Vue.component('BtnSelection', BtnSelection)

Vue.component('NormalField', NormalField)
Vue.component('YesNoField', YesNoField)
Vue.component('PropertyTypeField', PropertyTypeField)
Vue.component('ZoneOverlayField', ZoneOverlayField)
Vue.component('MultiField', MultiField)

Vue.component('ConfirmModal', ConfirmModal)

// Vue.use(PerfectScrollbar)
Vue.use(require('vue-pusher'), {
  api_key: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER
  }
})

Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
